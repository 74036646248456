<template>
    <!-- 会员中心 -->
    <div class="finished">
      <div class="project">
          <h3 class="title">质押担保申请</h3>
        <div class="steps">
          <my-steps ref="mySteps" :steps="steps" @getCurrentStep="getCurrentStep">
            <div class="steps-mid">
              <steps-form :active="active" :type="type"></steps-form>
            </div>
          </my-steps>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import StepsForm from "../components/stepsForm"
  import mySteps from "@/components/mySteps/index";
  export default {
    components:{
      StepsForm,
      mySteps
    },
    data() {
      return {
        type:2,
        active: 0,
        steps: [
          "填写贷款信息",
          "填写个人信息",
          "填写产权信息",
          "填写联系人信息",
          "上传相关材料",
          "提交成功"
        ]
  
      };
    },
    methods: {
      getCurrentStep(val) {
        this.active = val;
      },
      next() {
        this.$refs.mySteps.next();
      }
    }
  };
  </script>
  
  <style lang="less" scoped>
  /deep/ .el-radio__input {
    display: none;
  }
  /deep/ .el-radio {
    margin-right: 0;
  }
  /deep/ .el-button--primary {
    width: 256px;
  }
  .finished {
    text-align: left;
    height: 100%;
    position: relative;
    // margin-top: 46px;
  
    h3{
    text-indent: 30px;
  }
    .project {
      // margin-top: 58px;
      .table-con {
        margin-top: 12px;
        padding: 0 15px 0 18px;
      }
    }
    .steps {
      margin-top: 62px;
      &-mid {
        margin-top: 58px;
      }
    }
  }
  </style>
  
  <style lang="less"></style>
  